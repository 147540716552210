//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Slide extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2 v4y6u1", title: Title, href: Target },
            //Element
            Title
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Slide.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Slide;