//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Top from "../Content/Slider/Top";
//Install
import End from "../Content/Slider/End";
//Install
import Bottom from "../Content/Slider/Bottom";

//Element
class Product extends Component {
    //Element
    N201R6 = () => {
        //Element
        let X213GP = document.getElementById( "Sticky_Header" )
        //Element
        let A061J5 = X213GP.offsetTop
        //Element
        if( window.pageYOffset > A061J5 ){
            //Element
            X213GP.classList.add( "d3348h" )
            //Element
            X213GP.classList.remove( "d-none" )
        //Element
        } else {
            //Element
            X213GP.classList.add( "d-none" )
        }
    }
    //Element
    F87Y6A = () => {
        //Element
        let ZQ2T47 = document.getElementById( "Web_ZQ2T47" )
        //Element
        let B323HG = document.getElementById( "Web_P2T66Z" )
        //Element
        ZQ2T47.classList.add( "d-none" )
        //Element
        B323HG.classList.add( "d-none" )
    }
    //Element
    componentDidMount(){
        //Element
        this.F87Y6A()
        //Element
        let BPO234 = document.getElementsByClassName( "xl2233" )[0]
        //Element
        let DQ5745 = document.getElementsByClassName( "k44547" )[0]
        //Element
        let J253C6 = document.querySelectorAll( ".q618vm li a" )
        //Element
        let MD13Y7 = document.querySelector( ".q618vm li:first-child a" )
        //Element
        let L77J4J = document.querySelector( ".q618vm li:first-child a strong" )
        //Element
        let X213GP = document.getElementById( "Sticky_Header" )
        //Element
        window.addEventListener( "scroll", this.N201R6 )
        //Element
        for( let One = 0; One < J253C6.length; One++ ) {
            //Element
            J253C6[One].style.color = "#BDBDBD"
        }
        //Element
        MD13Y7.style.color = "#525051"
        //Element
        BPO234.classList.remove( "d-none" )
        //Element
        DQ5745.classList.add( "bg-white" )
        //Element
        MD13Y7.classList.add( "t349o8" )
        //Element
        X213GP.classList.add( "d-none" )
        //Element
        L77J4J.style.color = "#525051"
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W7U51A",
                //Element
                prevEl      : "#Web_S1110M"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v3p702" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                        //Element
                        React.createElement( Top, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                        //Element
                        React.createElement( End, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                        //Element
                        React.createElement( Bottom, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Product;