//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W2A436 } from "../../../Media/Icon/046.svg";

//Element
class Resource extends Component {
    //Element
    S478UF = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".r85rm6 li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "h642y2" )
        }
        //Element
        e.target.classList.add( "h642y2" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "float-start w-100 p-0 m-0 r85rm6" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase h642y2", href: "/", onClick: this.S478UF },
                    //Element
                    "Belgeler",
                    //Element
                    React.createElement( W2A436, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase", href: "/", onClick: this.S478UF },
                    //Element
                    "Broşürler",
                    //Element
                    React.createElement( W2A436, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Resource;