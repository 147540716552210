//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Featured from "../../../Media/Image/006.png";
//Install
import propTypes from "prop-types";

//Element
class Bottom extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 y986qm", title: Title, href: Target },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 d-none y0d27f" },
                            //Element
                            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 text-center w31h9e" },
                                //Element
                                "Tüm Hava Koşullarına",
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                React.createElement( "strong", {},
                                    //Element
                                    "Karşı Maksimum"
                                ),
                                //Element
                                React.createElement( "br", {} ),
                                //Element
                                "Koruma"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 g102a0" },
                                //Element
                                "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar."
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center e166j6" },
                                //Element
                                "Hemen Bakın"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 m8pz02" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: Featured, width: "636", height: "636" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Bottom.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Bottom;