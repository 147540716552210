//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Y8411J } from "../../../Media/Social/003.svg";
//Install
import propTypes from "prop-types";

//Element
class Instagram extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Media, Target } = this.props
        //Element
        let Source = "https://img.freepik.com/premium-photo/"
        //Element
        let Photo  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 m9i423", target: "_blank", href: "/" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 position-relative a72984" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Photo, width: "196", height: "auto" } ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 v8o01v" },
                    //Element
                    React.createElement( Y8411J, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Instagram.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Instagram;