//Install
import React, { Component } from "react";
//Install
import { ReactComponent as E70201 } from "../../Media/Icon/003.svg";
//Install
import { ReactComponent as C635LB } from "../../Media/Icon/003.svg";

//Element
class Navigation extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 sg6544" },
            //Element
            React.createElement( "button", { id: "Web_D7062T", name: "Web_D7062T", className: "float-start w-auto p-0 m-0 me-4 bg-transparent border-0 ea333e", type: "button" },
                //Element
                React.createElement( C635LB, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_Z474XF", name: "Web_Z474XF", className: "float-start w-auto p-0 m-0 bg-transparent border-0 hsp523", type: "button" },
                //Element
                React.createElement( E70201, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Navigation;