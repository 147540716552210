//Install
import React, { Component } from "react";
//Install
import { ReactComponent as CG4333 } from "../../../Media/Icon/038.svg";
//Install
import { ReactComponent as UW504B } from "../../../Media/Icon/039.svg";
//Install
import { ReactComponent as U0I716 } from "../../../Media/Icon/040.svg";
//Install
import { ReactComponent as Z3M20W } from "../../../Media/Icon/041.svg";

//Element
class User extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 v3a172" },
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Satış Noktası", href: "/" },
                    //Element
                    "Satış Noktası"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Kurumsal", href: "/" },
                    //Element
                    "Kurumsal",
                    //Element
                    React.createElement( CG4333, {} )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-1 ps-3 pe-3 m-0 position-absolute p969v1" },
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 y5wo42" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                "Kurumsal"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                "Tarihçe"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                "Kariyer"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "EN", href: "/" },
                    //Element
                    React.createElement( UW504B, {} ),
                    //Element
                    "EN",
                    //Element
                    React.createElement( CG4333, {} )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-1 ps-3 pe-3 m-0 position-absolute p969v1" },
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 y5wo42" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                React.createElement( UW504B, {} ),
                                //Element
                                "EN"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                React.createElement( U0I716, {} ),
                                //Element
                                "DE"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                //Element
                                React.createElement( Z3M20W, {} ),
                                //Element
                                "NL"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default User;