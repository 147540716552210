//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 u326ia" },
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 z560z1" },
                        //Element
                        Title
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 p500iw" },
                    //Element
                    React.createElement( "li", {},
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 me-4", title: "Bilgi Güvenliği Politikası", href: "/" },
                            //Element
                            "Bilgi Güvenliği Politikası"
                        )
                    ),
                    //Element
                    React.createElement( "li", {},
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 me-4", title: "Çerez Politikaları", href: "/" },
                            //Element
                            "Çerez Politikaları"
                        )
                    ),
                    //Element
                    React.createElement( "li", {},
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Başvuru Formu", href: "/" },
                            //Element
                            "Başvuru Formu"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Copyright.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Copyright;