//Install
import React, { Component } from "react";
//Install
import { ReactComponent as K34G92 } from "../../Media/Icon/002.svg";

//Element
class Newsletter extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 m-0 zw525n" },
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 d0g70o" },
                //Element
                "Bültenimize abone olun"
            ),
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-2 position-relative m65w67" },
                //Element
                React.createElement( "input", { id: "Web_X91617", name: "Web_X91617", className: "float-start w-100 p-0 m-0 v85n86", placeholder: "E-posta", type: "email", required: "required" } ),
                //Element
                React.createElement( "button", { id: "Web_L0V80C", name: "Web_L0V80C", className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 bg-transparent position-absolute end-0 top-0 kw7064", type: "button" },
                    //Element
                    React.createElement( K34G92, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Newsletter;