//Install
import React, { Component } from "react";
//Install
import N922GN from "../../../Media/Image/013.png";

//Element
class Friend extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 c7h994" },
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center p-0 m-0", title: "Example.com", href: "/" },
                    //Element
                    React.createElement( "img", { alt: "SYZ Group", title: "SYZ Group", src: N922GN, width: "10", height: "10" } ),
                    //Element
                    "SYZ Group"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Example.com", href: "/" },
                    //Element
                    "ZIP Perde"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Friend;