//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Y8003Y } from "../../Media/Social/001.svg";
//Install
import { ReactComponent as V4PF53 } from "../../Media/Social/002.svg";
//Install
import { ReactComponent as S419DX } from "../../Media/Social/003.svg";
//Install
import { ReactComponent as L01XU1 } from "../../Media/Social/004.svg";
//Install
import { ReactComponent as Q35P0G } from "../../Media/Social/005.svg";
//Install
import propTypes from "prop-types";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const { ID, Facebook, X, Instagram, Youtube, Linkedin } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 w77s80" },
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2 mt-4", title: "Facebook", href: Facebook },
                    //Element
                    React.createElement( Y8003Y, {} )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2 ms-4 mt-4", title: "X", href: X },
                    //Element
                    React.createElement( V4PF53, {} )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2 ms-4 mt-4", title: "Instagram", href: Instagram },
                    //Element
                    React.createElement( S419DX, {} )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2 ms-4 mt-4", title: "Youtube", href: Youtube },
                    //Element
                    React.createElement( Q35P0G, {} )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2 ms-4 mt-4", title: "Linkedin", href: Linkedin },
                    //Element
                    React.createElement( L01XU1, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Social.propTypes = {
    ID        : propTypes.string.isRequired,
    Facebook  : propTypes.string.isRequired,
    X         : propTypes.string.isRequired,
    Instagram : propTypes.string.isRequired,
    Youtube   : propTypes.string.isRequired,
    Linkedin  : propTypes.string.isRequired
}
//Element
export default Social;