//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W2A436 } from "../../../Media/Icon/046.svg";

//Element
class Solution extends Component {
    //Element
    AN687K = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".n298c2 li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "d8340a" )
        }
        //Element
        e.target.classList.add( "d8340a" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "float-start w-100 p-0 m-0 n298c2" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase d8340a", href: "/", onClick: this.AN687K },
                    //Element
                    "Öne Çıkanlar",
                    //Element
                    React.createElement( W2A436, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase", href: "/", onClick: this.AN687K },
                    //Element
                    "İşletme",
                    //Element
                    React.createElement( W2A436, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase", href: "/", onClick: this.AN687K },
                    //Element
                    "Konut",
                    //Element
                    React.createElement( W2A436, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center text-uppercase", href: "/", onClick: this.AN687K },
                    //Element
                    "Mimari",
                    //Element
                    React.createElement( W2A436, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Solution;