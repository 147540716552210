//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W435KH } from "../../../../Media/Icon/023.svg";
//Install
import { ReactComponent as E2871U } from "../../../../Media/Icon/024.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../../Media/Icon/025.svg";
//Install
import { ReactComponent as Q2003G } from "../../../../Media/Icon/026.svg";

//Element
class Icon extends Component {
    //Element
    CH616O = (e) => {
        //Element
        let C4E64R = document.getElementById( "Web_H888VY" )
        //Element
        let CV903R = document.getElementById( "Web_V6SV79" )
        //Element
        let EO72U1 = document.getElementById( "Web_F99B61" )
        //Element
        let D47UE6 = document.getElementById( "Web_S3V424" )
        //Element
        let C12SQ7 = document.getElementById( "Web_C12SQ7" )
        //Element
        let UC155J = document.getElementById( "Web_UC155J" )
        //Element
        let G939FS = document.getElementById( "Web_G939FS" )
        //Element
        let QU6114 = document.getElementById( "Web_QU6114" )
        //Element
        C4E64R.classList.add( "d-none" )
        //Element
        CV903R.classList.add( "d-none" )
        //Element
        EO72U1.classList.add( "d-none" )
        //Element
        D47UE6.classList.add( "d-none" )
        //Element
        C4E64R.classList.remove( "d-none" )
        //Element
        C12SQ7.classList.remove( "opacity-100" )
        //Element
        UC155J.classList.remove( "opacity-100" )
        //Element
        G939FS.classList.remove( "opacity-100" )
        //Element
        QU6114.classList.remove( "opacity-100" )
        //Element
        C12SQ7.classList.add( "opacity-100" )
    }
    //Element
    B0I47E = (e) => {
        //Element
        let C4E64R = document.getElementById( "Web_H888VY" )
        //Element
        let CV903R = document.getElementById( "Web_V6SV79" )
        //Element
        let EO72U1 = document.getElementById( "Web_F99B61" )
        //Element
        let D47UE6 = document.getElementById( "Web_S3V424" )
        //Element
        let C12SQ7 = document.getElementById( "Web_C12SQ7" )
        //Element
        let UC155J = document.getElementById( "Web_UC155J" )
        //Element
        let G939FS = document.getElementById( "Web_G939FS" )
        //Element
        let QU6114 = document.getElementById( "Web_QU6114" )
        //Element
        C4E64R.classList.add( "d-none" )
        //Element
        CV903R.classList.add( "d-none" )
        //Element
        EO72U1.classList.add( "d-none" )
        //Element
        D47UE6.classList.add( "d-none" )
        //Element
        CV903R.classList.remove( "d-none" )
        //Element
        C12SQ7.classList.remove( "opacity-100" )
        //Element
        UC155J.classList.remove( "opacity-100" )
        //Element
        G939FS.classList.remove( "opacity-100" )
        //Element
        QU6114.classList.remove( "opacity-100" )
        //Element
        UC155J.classList.add( "opacity-100" )
    }
    //Element
    V0ZW08 = (e) => {
        //Element
        let C4E64R = document.getElementById( "Web_H888VY" )
        //Element
        let CV903R = document.getElementById( "Web_V6SV79" )
        //Element
        let EO72U1 = document.getElementById( "Web_F99B61" )
        //Element
        let D47UE6 = document.getElementById( "Web_S3V424" )
        //Element
        let C12SQ7 = document.getElementById( "Web_C12SQ7" )
        //Element
        let UC155J = document.getElementById( "Web_UC155J" )
        //Element
        let G939FS = document.getElementById( "Web_G939FS" )
        //Element
        let QU6114 = document.getElementById( "Web_QU6114" )
        //Element
        C4E64R.classList.add( "d-none" )
        //Element
        CV903R.classList.add( "d-none" )
        //Element
        EO72U1.classList.add( "d-none" )
        //Element
        D47UE6.classList.add( "d-none" )
        //Element
        EO72U1.classList.remove( "d-none" )
        //Element
        C12SQ7.classList.remove( "opacity-100" )
        //Element
        UC155J.classList.remove( "opacity-100" )
        //Element
        G939FS.classList.remove( "opacity-100" )
        //Element
        QU6114.classList.remove( "opacity-100" )
        //Element
        G939FS.classList.add( "opacity-100" )
    }
    //Element
    P61765 = (e) => {
        //Element
        let C4E64R = document.getElementById( "Web_H888VY" )
        //Element
        let CV903R = document.getElementById( "Web_V6SV79" )
        //Element
        let EO72U1 = document.getElementById( "Web_F99B61" )
        //Element
        let D47UE6 = document.getElementById( "Web_S3V424" )
        //Element
        let C12SQ7 = document.getElementById( "Web_C12SQ7" )
        //Element
        let UC155J = document.getElementById( "Web_UC155J" )
        //Element
        let G939FS = document.getElementById( "Web_G939FS" )
        //Element
        let QU6114 = document.getElementById( "Web_QU6114" )
        //Element
        C4E64R.classList.add( "d-none" )
        //Element
        CV903R.classList.add( "d-none" )
        //Element
        EO72U1.classList.add( "d-none" )
        //Element
        D47UE6.classList.add( "d-none" )
        //Element
        D47UE6.classList.remove( "d-none" )
        //Element
        C12SQ7.classList.remove( "opacity-100" )
        //Element
        UC155J.classList.remove( "opacity-100" )
        //Element
        G939FS.classList.remove( "opacity-100" )
        //Element
        QU6114.classList.remove( "opacity-100" )
        //Element
        QU6114.classList.add( "opacity-100" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-auto p-0 m-0 k1c43z" },
            //Element
            React.createElement( "button", { id: "Web_C12SQ7", name: "Web_C12SQ7", className: "float-start w-auto p-0 m-0 bg-transparent border-0 opacity-100 ns626n", type: "button", onClick: this.CH616O },
                //Element
                React.createElement( W435KH, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_UC155J", name: "Web_UC155J", className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 ns626n", type: "button", onClick: this.B0I47E },
                //Element
                React.createElement( E2871U, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_G939FS", name: "Web_G939FS", className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 ns626n", type: "button", onClick: this.V0ZW08 },
                //Element
                React.createElement( L90YU5, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_QU6114", name: "Web_QU6114", className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 ns626n", type: "button", onClick: this.P61765 },
                //Element
                React.createElement( Q2003G, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Icon;