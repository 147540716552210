//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Image extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Media, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 u28q2v" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 oo3301" },
                
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Image.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Image;