//Install
import React, { Component } from "react";
//Install
import { ReactComponent as G06ET1 } from "../../../Media/Icon/028.svg";
//Install
import { ReactComponent as Y8650B } from "../../../Media/Icon/029.svg";
//Install
import { ReactComponent as G424Z5 } from "../../../Media/Icon/030.svg";
//Install
import { ReactComponent as H5C3X3 } from "../../../Media/Icon/031.svg";

//Element
class SET extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Web_V159K4", className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 zw822k" },
            //Element
            React.createElement( "button", { className: "d-flex w-auto flex-column justify-content-center align-items-center bg-transparent border-0 p-0 m-0 me-3 c093jv", type: "button" },
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent d9952q" },
                    //Element
                    React.createElement( G06ET1, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 m0520a" },
                    //Element
                    "Favoriler"
                )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto flex-column justify-content-center align-items-center bg-transparent border-0 p-0 m-0 me-3 c093jv", type: "button" },
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent d9952q" },
                    //Element
                    React.createElement( Y8650B, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 m0520a" },
                    //Element
                    "Duyurular"
                )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto flex-column justify-content-center align-items-center bg-transparent border-0 p-0 m-0 me-3 c093jv", type: "button" },
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent d9952q" },
                    //Element
                    React.createElement( G424Z5, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 m0520a" },
                    //Element
                    "Blog"
                )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto flex-column justify-content-center align-items-center bg-transparent border-0 p-0 m-0 c093jv", type: "button" },
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent d9952q" },
                    //Element
                    React.createElement( H5C3X3, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 m0520a" },
                    //Element
                    "Giriş Yap"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;