//Install
import React, { Component } from "react";

//Element
class Start extends Component {
    //Element
    P481AS = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".xh0f44 li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "b0627q" )
        }
        //Element
        e.target.classList.add( "b0627q" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 d854to" },
            //Element
            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 xh0f44" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative b0627q", href: "/", onClick: this.P481AS },
                        //Element
                        "Esinlen"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 ms-4 position-relative", href: "/", onClick: this.P481AS },
                        //Element
                        "Çözümler"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Start;