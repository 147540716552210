//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Z3UI31 } from "../../../Media/Icon/002.svg";
//Install
import { ReactComponent as W435KH } from "../../../Media/Icon/042.svg";
//Install
import { ReactComponent as E2871U } from "../../../Media/Icon/043.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../Media/Icon/044.svg";
//Install
import { ReactComponent as Q2003G } from "../../../Media/Icon/045.svg";

//Element
class Filter extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end p-0 m-0 pgi752" },
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 e568ib", type: "button" },
                //Element
                React.createElement( W435KH, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 e568ib", type: "button" },
                //Element
                React.createElement( E2871U, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 e568ib", type: "button" },
                //Element
                React.createElement( L90YU5, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 e568ib", type: "button" },
                //Element
                React.createElement( Q2003G, {} )
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-3 text-uppercase xn000f", href: "/" },
                //Element
                "Esin Kaynağı",
                //Element
                React.createElement( Z3UI31, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Filter;