//Install
import React, { Component } from "react";
//Install
import { ReactComponent as IN5X34 } from "../../../Media/Icon/027.svg";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-4 m-0 mt-4 eb0362" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 f808kk", href: "/" },
                        //Element
                        React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r288z6" },
                            //Element
                            React.createElement( IN5X34, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 x9el67" },
                            //Element
                            "Stove"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;