//Install
import React, { Component } from "react";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 nn5545" },
            //Element
            //Element
            React.createElement( "img", { alt: "Banner", title: "Banner", src: "https://www.garantibbva.com.tr/content/dam/public-website/kendim-icin/menu/tr/yüksek-compressed/emekli_maas_odemeleri_210x3721.jpg", width: "261", height: "462" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;