//Install
import React, { Component } from "react";
//Install
import { ReactComponent as C816B1 } from "../../../Media/Icon/047.svg";

//Element
class More extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 r881l2" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 zao882", type: "button" },
                //Element
                "Daha Fazla Yükle",
                //Element
                React.createElement( C816B1, {} ) 
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default More;