//Install
import React, { Component } from "react";
//Install
import N6B3O4 from "../../Media/Image/010.png";

//Element
class Brand extends Component {
    //Element
    E3A54E = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-auto justify-content-start align-items-center p-0 m-0 bg-transparent border-0 b1917l", type: "button", onClick: this.E3A54E },
            //Element
            React.createElement( "img", { alt: "SYZ Grup", title: "SYZ Grup", src: N6B3O4, width: "130", height: "73" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;