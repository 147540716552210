//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as K34G92 } from "../../../../Media/Icon/002.svg";
//Install
import E26L14 from "../../../../Media/Image/004.png";
//Install
import propTypes from "prop-types";

//Element
class Two extends Component {
    //Element
    render() {
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-3 m-0 mt-3 d8416n", href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c049t1" },
                        //Element
                        React.createElement( "img", { alt: "e-Vadeli Hesap", title: "e-Vadeli Hesap", src: E26L14, width: "100", height: "auto" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 id404j" },
                        //Element
                        React.createElement( "p", { className: "d-flex w-100 justify-content-between p-0 m-0 z84b55" },
                            //Element
                            "Urunler",
                            //Element
                            React.createElement( "strong", {},
                                //Element
                                "3"
                            )
                        ),
                        //Element
                        React.createElement( "h6", { className: "d-flex w-100 justify-content-start p-0 m-0 fa3w12" },
                            //Element
                            "e-Vadeli Hesap",
                            //Element
                            React.createElement( K34G92, {} )
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 z84b55" },
                            //Element
                            "Paranızı Garanti BBVA değerlendirin."
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Two.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Two;