//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import SET from "./Header/Classic/SET";
//Install
import GET from "./Header/Modern/GET";
//Install
import User from "./Header/Classic/User";
//Install
import Brand from "./Header/Brand";
//Install
import Sticky from "./Shortcode/Header/Sticky";
//Install
import Friend from "./Header/Classic/Friend";
//Install
import Classic from "./Header/Classic/Menu";
//Install
import Modern from "./Header/Modern/Menu";
//Install
import Breaking from "./Header/Classic/Breaking";
//Install
import Hamburger from "./Header/Hamburger";
//Install
import Information from "./Header/Classic/Information";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W7U51A",
                //Element
                prevEl      : "#Web_S1110M"
            },
            //Element
            pagination      : {
                //Element
                el          : ".p441qs",
                //Element
                clickable   : true
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 z222fj" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none h61r9d" },
                //Element
                React.createElement( Breaking, { ID: "0", Title: "Muud ve Tivibu GO’da Mobil Ödeme ile Harca, Kullanım Bedeli Ödeme", Target: "/" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 h61r9d" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Friend, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( Information, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( User, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative k44547" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( Hamburger, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Brand, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Classic, {} ),
                            //Element
                            React.createElement( Modern, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( SET, {} ),
                            //Element
                            React.createElement( GET, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Sticky, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;