//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Default from "../Content/Slider/Default";

//Element
class Solution extends Component {
    //Element
    N201R6 = () => {
        //Element
        let X213GP = document.getElementById( "Sticky_Header" )
        //Element
        let A061J5 = X213GP.offsetTop
        //Element
        if( window.pageYOffset > A061J5 ){
            //Element
            X213GP.classList.add( "d3348h" )
            //Element
            X213GP.classList.remove( "d-none" )
        //Element
        } else {
            //Element
            X213GP.classList.add( "d-none" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        let ZQ2T47 = document.getElementById( "Web_UR517X" )
        //Element
        let H45O5R = document.getElementsByClassName( "k44547" )[0]
        //Element
        let B323HG = document.getElementById( "Web_V159K4" )
        //Element
        let MD13Y7 = document.querySelector( ".w85zu2 li:nth-child(2) a" )
        //Element
        let M4C36Z = document.querySelectorAll( ".w85zu2 li a" )
        //Element
        window.addEventListener( "scroll", this.N201R6 )
        //Element
        for (let Index = 0; Index < M4C36Z.length; Index++) {
            //Element
            M4C36Z[Index].classList.add( "ft4k63" )
        }
        //Element
        ZQ2T47.classList.add( "d-none" )
        //Element
        B323HG.classList.add( "d-none" )
        //Element
        H45O5R.classList.add( "bn6b54" )
        //Element
        MD13Y7.classList.add( "i829uf" )
        //Element
        MD13Y7.classList.remove( "ft4k63" )
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W7U51A",
                //Element
                prevEl      : "#Web_S1110M"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v3p702" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 overflow-hidden", ...Slider },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                        //Element
                        React.createElement( Default, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Solution;