//Install
import React, { Component } from "react";
//Install
import Featured from "../../../Media/Image/006.png";
//Install
import Product from "../../Shortcode/Card/Product";
//Install
import { ReactComponent as W93SP8 } from "../../../Media/Icon/033.svg";
//Install
import propTypes from "prop-types";

//Element
class Top extends Component {
    //Element
    R82IM4 = (e) => {
        //Element
        e.preventDefault()
        //Element 
        let H0QX30 = document.getElementsByClassName( "k69jr3" )[1]
        //Element
        let P6071Q = document.getElementsByClassName( "xs002z" )[1]
        //Element
        let Q92K35 = document.getElementsByClassName( "c9w8b5" )[1]
        //Element
        let Z7856J = document.getElementsByClassName( "hvm661" )[1]
        //Element
        H0QX30.classList.toggle( "d-none" )
        //Element
        H0QX30.classList.toggle( "ms-5" )
        //Element
        P6071Q.classList.toggle( "v2gx07" )
        //Element
        P6071Q.classList.toggle( "je8h89" )
        //Element
        Q92K35.classList.toggle( "p24663" )
        //Element
        Z7856J.classList.toggle( "d-none" )
        //Element
        P6071Q.style.minWidth = "75px";
        //Element
        P6071Q.style.maxWidth = "75px";
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 y986qm", title: Title, href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 m-0 position-relative b34t0q" },
                //Element
                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 m8pz02" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Featured, width: "636", height: "636" } )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 t2i742" },
                    //Element
                    React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle c9w8b5", onClick: this.R82IM4 },
                        //Element
                        React.createElement( W93SP8, {} ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-absolute xs002z" } ),
                        //Element
                        React.createElement( Product, {} )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 ps-4 pe-5 m-0 m8pz02" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 hvm661" },
                    //Element
                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 di4354" },
                        //Element
                        "Tüm Hava Koşullarına Karşı Üstün Koruma"
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 ozw032" },
                        //Element
                        "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır."
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 ozw032" },
                        //Element
                        "İster ticari mülklerde, ister konut yapılarında, ister kamusal alanlarda uygulansın, bu sistemler koruma ve tasarım iyileştirmenin bir karışımını sunar."
                    ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 m8880y" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 text-center b37816" },
                            //Element
                            "Hemen Bakın"
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 ms-3 text-center bn600f" },
                            //Element
                            "Nerede Kullanılır?"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Top.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Top;