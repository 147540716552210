//Install
import React, { Component } from "react";
//Install
import { ReactComponent as R292N8 } from "../../../../Media/Icon/012.svg";
//Install
import { ReactComponent as X34G83 } from "../../../../Media/Icon/002.svg";

//Element
class Text extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-end w-auto p-0 m-0" },
            //Element
            React.createElement( "button", { className: "float-end w-auto p-0 m-0 bg-transparent border-0 x2546d", type: "button" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                    //Element
                    "Tümünü Gör",
                    //Element
                    React.createElement( X34G83, {} )
                )
            ),
            //Element
            React.createElement( "button", { className: "float-end w-auto p-0 m-0 me-2 bg-transparent border-0 kgt509", type: "button" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                    //Element
                    React.createElement( R292N8, {} )
                )
            ),
            //Element
            React.createElement( "button", { className: "float-end w-auto p-0 m-0 bg-transparent border-0 d1m9j1", type: "button" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                    //Element
                    React.createElement( R292N8, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Text;