//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Z92H5B } from "../../../Media/Icon/004.svg";
//Install
import propTypes from "prop-types";

//Element
class Menu extends Component {
    //Element
    N222OL = (e) => {
        //Element
        e.preventDefault()
        //Element
        let WH4918 = document.querySelectorAll( ".i37l4p" )
        //Element
        let PU832G = document.getElementsByClassName( "cf5716" )[0]
        //Element
        let EN954E = document.getElementsByClassName( "h8v662" )[0]
        //Element
        let T659S3 = document.querySelectorAll( ".k44547" )
        //Element
        let Z6Y8W0 = document.querySelectorAll( ".q618vm" )
        //Element
        let D2TR29 = document.querySelectorAll( ".q618vm li a" )
        //Element
        let B54P0U = document.querySelectorAll( ".b41y5c" )
        //Element
        let B54PO0 = document.querySelectorAll( ".c758ih" )
        //Element
        let D2J847 = document.querySelectorAll( ".si665q" )
        //Element
        for (let One = 0; One < WH4918.length; One++) {
            //Element
            WH4918[One].classList.toggle( "d-none" )
        }
        //Element
        for (let Two = 0; Two < T659S3.length; Two++) {
            //Element
            T659S3[Two].classList.toggle( "bg-white" )
            //Element
            T659S3[Two].classList.toggle( "j91c57" )
        }
        //Element
        for (let Three = 0; Three < Z6Y8W0.length; Three++) {
            //Element
            Z6Y8W0[Three].classList.toggle( "me4b92" )
        }
        //Element
        for (let Four = 0; Four < D2TR29.length; Four++) {
            //Element
            D2TR29[Four].classList.toggle( "qi6d24" )
            //Element
            D2TR29[Four].classList.toggle( "z9X51G" )
        }
        //Element
        for (let Five = 0; Five < B54P0U.length; Five++) {
            //Element
            B54P0U[Five].classList.toggle( "y7r6v9" )
        }
        //Element
        for (let Six = 0; Six < B54PO0.length; Six++) {
            //Element
            B54PO0[Six].classList.toggle( "y7r6v9" )
        }
        //Element
        for (let Seven = 0; Seven < D2J847.length; Seven++) {
            //Element
            D2J847[Seven].classList.toggle( "q894z8" )
        }
        //Element
        e.target.classList.toggle( "t349o8" )
        //Element
        e.target.classList.remove( "qi6d24" )
        //Element
        e.target.classList.remove( "z9X51G" )
    }
    //Element
    S9Q3J3 = (e) => {
        //Element
        let M4C36Z = document.querySelectorAll( ".w85zu2 li a" )
        //Element
        for (let Index = 0; Index < M4C36Z.length; Index++) {
            //Element
            M4C36Z[Index].classList.toggle( "ft4k63" )
        }
        //Element
        e.target.classList.remove( "ft4k63" )
    }
    //Element
    render() {
        //Element
        const { ID } = this.props
        //Element
        const Content = React.createElement( "nav", { id: "Web_ZQ2T47", className: "float-start w-100 p-0 m-0 position-relative iyi618" },
            //Element
            React.createElement( "menu", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 w85zu2" },
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase q37535", title: "Ürünler", href: "/urunler", onMouseOver: this.S9Q3J3, onMouseOut: this.S9Q3J3 },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Neler Yapıldı"
                        )
                    )
                ),
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase q37535", title: "Çözümler", href: "/cozumler", onMouseOver: this.S9Q3J3, onMouseOut: this.S9Q3J3 },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Çözümler"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Fikir Edin"
                        )
                    )
                ),
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase q37535", title: "Esinlen", href: "/", onMouseOver: this.S9Q3J3, onMouseOut: this.S9Q3J3 },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Esinlen"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "İlham Al"
                        )
                    )
                ),
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase q37535", title: "Keşfedin", href: "/", onMouseOver: this.S9Q3J3, onMouseOut: this.S9Q3J3 },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Keşfet"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Eklentiler"
                        )
                    )
                ),
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase q37535", title: "S.S.S", href: "/", onMouseOver: this.S9Q3J3, onMouseOut: this.S9Q3J3 },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Daha Fazlası"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Araçlar ve Kaynaklar"
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 zw822k" },
                        //Element
                        React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 me-2 bg-transparent border-0 z7707r", type: "button" },
                            //Element
                            React.createElement( Z92H5B, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Menu.propTypes = {
    ID : propTypes.string.isRequired
}
//Element
export default Menu;