//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Z92H5B } from "../../Media/Icon/007.svg";

//Element
class Hamburger extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-auto p-0 m-0 zw822k" },
            //Element
            React.createElement( "button", { className: "d-flex w-auto flex-column justify-content-center align-items-start p-0 m-0 me-2 bg-transparent border-0 u856si", type: "button" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d4674b" },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 c88u52" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 position-relative i9l839" } ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 position-relative dmb039" } ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 position-relative j267vp" } )
                    )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 e422l5" },
                    //Element
                    "Menü"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Hamburger;