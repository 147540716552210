//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 wr049s" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 z16a39" },
                        //Element
                        "Kurumsal"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 x34v3n" },
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Genel Bilgilendirme", href: "/" },
                                //Element
                                "Genel Bilgilendirme"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Medya", href: "/" },
                                //Element
                                "Medya"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Blog", href: "/" },
                                //Element
                                "Blog"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Kariyer", href: "/" },
                                //Element
                                "Kariyer"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Bilgi Toplumu Hizmetleri", href: "/" },
                                //Element
                                "Bilgi Toplumu Hizmetleri"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 wr049s" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 z16a39" },
                        //Element
                        "Ürünler"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 x34v3n" },
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Tenteler ve Kapamalar", href: "/" },
                                //Element
                                "Tenteler ve Kapamalar"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Pergolalar", href: "/" },
                                //Element
                                "Pergolalar"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Carport", href: "/" },
                                //Element
                                "Carport"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Kış Bahçeleri", href: "/" },
                                //Element
                                "Kış Bahçeleri"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Cam Sistemleri", href: "/" },
                                //Element
                                "Cam Sistemleri"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 wr049s" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 z16a39" },
                        //Element
                        "Nerede Kullanılır?"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 x34v3n" },
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Konut", href: "/" },
                                //Element
                                "Konut"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Ticari", href: "/" },
                                //Element
                                "Ticari"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Tümünü Göster", href: "/" },
                                //Element
                                "Tümünü Göster"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 wr049s" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 z16a39" },
                        //Element
                        "Müşteri Hizmetleri"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 x34v3n" },
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "WhatsApp Destek", href: "/" },
                                //Element
                                "WhatsApp Destek"
                            )
                        ),
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "E-posta", href: "/" },
                                //Element
                                "E-posta"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 wr049s" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 z16a39" },
                        //Element
                        "Hemen Ulaşın"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 x34v3n" },
                        //Element
                        React.createElement( "li", {},
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "İletişim", href: "/" },
                                //Element
                                "İletişim"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;