//Install
import React, { Component } from "react";
//Install
import { ReactComponent as AO147M } from "../../../Media/Icon/003.svg";
//Install
import YE000A from "../../../Media/Image/014.png";
//Install
import R57F8I from "../../../Media/Image/015.png";
//Install
import propTypes from "prop-types";

//Element
class Guide extends Component {
    //Element
    render() {
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        let Icon
        //Element
        if( ID == "1" ){
            //Element
            Icon = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 z8d261" },
                //Element
                React.createElement( "img", { alt: "CE", title: "CE", src: YE000A, width: "72", height: "72" } )
            )
        } else if( ID == "4" ){
            //Element
            Icon = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 z8d261" },
                //Element
                React.createElement( "img", { alt: "CE", title: "CE", src: R57F8I, width: "72", height: "72" } )
            )
        }
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-4 m-0 mt-3 position-relative f6hb66", href: "/" },
            //Element 
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b0b9i2" },
                //Element
                React.createElement( AO147M, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 position-absolute o45712" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 c4372c" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 sil914" },
                    //Element
                    Summary
                )
            ),
            //Element
            Icon
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Guide.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Guide;