//Install
import React, { Component } from "react";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 oh6687" },
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Bioklimatik Pergola"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Giyotin Cam"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "ZIP Perde"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Rüzgarlık"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Kış Bahçeleri"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Veranda"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Tente"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", title: "", href: "/" },
                    //Element
                    "Tüm Ürün Grupları"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;