//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Start from "./Content/Slider/Start";
//Install
import Top from "./Content/Slider/Top";
//Install
import End from "./Content/Slider/End";
//Install
import Tab from "./Content/Sub/Solution";
//Install
import More from "./Content/Button/More";
//Install
import Image from "./Shortcode/Card/Image";
//Install
import Guide from "./Content/Button/Guide";
//Install
import Bottom from "./Content/Slider/Bottom";
//Install
import Filter from "./Content/Tab/Filter";
//Install
import Horizontal from "./Content/Slider/Horizontal";
//Install
import Private from "./Content/Slider/Private";
//Install
import Campaign from "./Content/Navigation/Right";
//Install
import Bulletin from "./Content/Navigation/Top";
//Install
import Solution from "./Content/Navigation/Start";
//Install
import Resource from "./Content/Navigation/End";
//Install
import Document from "./Content/Sub/Resource";
//Install
import Instagram from "./Shortcode/Card/Instagram";
//Install
import { ReactComponent as L8869M } from "../Media/Icon/003.svg";

//Element
class Main extends Component {
    //Element
    N201R6 = () => {
        //Element
        let X213GP = document.getElementById( "Sticky_Header" )
        //Element
        let A061J5 = X213GP.offsetTop
        //Element
        if( window.pageYOffset > A061J5 ){
            //Element
            X213GP.classList.add( "d3348h" )
            //Element
            X213GP.classList.remove( "d-none" )
        //Element
        } else {
            //Element
            X213GP.classList.add( "d-none" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        window.addEventListener( "scroll", this.N201R6 )
        //Element
        let ZQ2T47 = document.getElementById( "Web_ZQ2T47" )
        //Element
        let B323HG = document.getElementById( "Web_P2T66Z" )
        //Element
        ZQ2T47.classList.add( "d-none" )
        //Element
        B323HG.classList.add( "d-none" )
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W7U51A",
                //Element
                prevEl      : "#Web_S1110M"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Campaigns = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_V20CP7",
                //Element
                prevEl      : "#Web_N3K42N"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v3p702" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w86037" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( Private, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( Top, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( End, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( Bottom, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( Start, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-4 pb-4 m-0 mt-4 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pt-3 pb-3 m-0 xl0325" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center i6e7k4" },
                                    //Element
                                    "Kampanyalar"
                                )
                            ),
                            //Element
                            React.createElement( Campaign, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-4 position-relative s7s2p8" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Campaigns },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 cg4d09" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N3K42N", name: "Web_N3K42N", className: "float-start w-100 rounded-circle position-relative v20u8h", type: "button" },
                                        //Element
                                        React.createElement( L8869M, {} )
                                    ),
                                    //Element
                                    React.createElement( "button", { id: "Web_V20CP7", name: "Web_V20CP7", className: "float-start w-100 rounded-circle position-relative u09ed7", type: "button" },
                                        //Element
                                        React.createElement( L8869M, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-4 pb-4 m-0 mt-4 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pt-3 pb-3 m-0 xl0325" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center i6e7k4" },
                                    //Element
                                    "Bülten"
                                )
                            ),
                            //Element
                            React.createElement( Bulletin, {} ),
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-4 position-relative s7s2p8" },
                                    //Element
                                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Campaigns },
                                        //Element
                                        React.createElement( SwiperSlide, {},
                                            //Element
                                            React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( SwiperSlide, {},
                                            //Element
                                            React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( SwiperSlide, {},
                                            //Element
                                            React.createElement( Horizontal, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır.", Media: "", Target: "/" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 cg4d09" },
                                        //Element
                                        React.createElement( "button", { id: "Web_N3K42N", name: "Web_N3K42N", className: "float-start w-100 rounded-circle position-relative v20u8h", type: "button" },
                                            //Element
                                            React.createElement( L8869M, {} )
                                        ),
                                        //Element
                                        React.createElement( "button", { id: "Web_V20CP7", name: "Web_V20CP7", className: "float-start w-100 rounded-circle position-relative u09ed7", type: "button" },
                                            //Element
                                            React.createElement( L8869M, {} )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Solution, {} ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d78164" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( Tab, {} )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( Filter, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 a627mg" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( Image, { ID: "0", Title: "", Media: "", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( More, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Resource, {} ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 yc5j10" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( Document, {} )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "1", Title: "CE", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "2", Title: "Uygunluk Beyanı", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "3", Title: "Bakım Klavuzu", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "4", Title: "ISO 9001", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "5", Title: "Teknik Şartname", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "6", Title: "Kullanım Kılavuzu", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "7", Title: "Sertifika", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( Guide, { ID: "8", Title: "Testler", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d78164" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d854to" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 xh0f44" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-auto" },
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative text-capitalize b0627q", href: "/" },
                                                //Element
                                                "Instagram'da Takip Edin"
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 qz291v" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "portrait-smiling-friends-standing-beach_1048944-28658596.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "portrait-cute-baby-girl-lying-home_1048944-16380304.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "medium-shot-family-retro-style_52683-115524.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "beautiful-lesbian-couple-celebrating-their-wedding-day-outdoors_23-2150637608.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "side-view-woman-holding-hands_1048944-16242081.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "psychological-therapy-support-group-meeting_23-2151044467.jpg", Target: "https://www.instagram.com" } )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;