//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Featured from "../../../Media/Image/009.jpg";
//Install
import propTypes from "prop-types";

//Element
class Start extends Component {
    //Element
    componentDidMount(){
        //Element
        let X82WI9 = document.getElementsByClassName( "c860a7" )[0]
        //Element
        X82WI9.style.backgroundImage = "url("+ Featured +")"
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 sh71l2", title: Title, href: Target },
            //Element
            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 c860a7" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Start.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Start;