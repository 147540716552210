//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as B5412T } from "../../../Media/Icon/001.svg"
//Install
import propTypes from "prop-types";

//Element
class Breaking extends Component {
    //Element
    render() {
        //Install
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 c5089c" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 x4890o" },
                            //Element
                            React.createElement( "a", { className: "float-start w-auto p-0 m-0 x5k769", href: Target },
                                //Element
                                Title,
                                //Element
                                React.createElement( B5412T, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Breaking.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Breaking;