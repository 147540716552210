//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../../Header/Submenu";
//Install
import D091EE from "../../../Media/Image/011.png";

//Element
class Sticky extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Sticky_Header", className: "float-start w-100 p-0 ps-4 m-0 position-fixed start-0 top-0 d-none va859w" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md: "1 offset-md-1" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center ho8398", href: "/" },
                            //Element
                            React.createElement( "img", { alt: "SYZ Grup", title: "SYZ Grup", src: D091EE, width: "41", height: "48" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:10 },
                        //Element
                        React.createElement( Submenu, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Sticky;